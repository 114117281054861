














































import { Component, Vue, Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import SignupModule from '@/store/modules/signup'
import { CommonItemConfiguration, Frequency } from '@/modules/common'
import sortBy from 'lodash/sortBy'

@Component({})
export default class PackDetailMobileCard extends Vue {
  signUpModule: SignupModule = getModule(SignupModule, this.$store);

  @Prop({ type: Object as () => CommonItemConfiguration })
  private CommonItemConfiguration!: CommonItemConfiguration;

  @Prop()
  frequency!: Frequency;

  @Prop()
  size!: boolean;

  @Prop()
  itemIndex!: number;

  get correctWidth() {
    return this.$vuetify.breakpoint.width - 24
  }

  get fontSize() {
    if (this.selectedItemName.length > 25) { return '21px; line-height:39px' }

    return '26px'
  }

  get packName() {
    if (this.CommonItemConfiguration) {
      return this.CommonItemConfiguration.name
    }
    return ''
  }

  get items() {
    return this.sortedItems
  }

  get selectedItem() {
    return this.items[this.itemIndex]
  }

  get sortedItems() {
    return sortBy(this.CommonItemConfiguration.items, (p) => p.displayOrder)
  }

  get selectedItemName() {
    if (this.selectedItem && this.selectedItem.item) {
      return this.selectedItem.item.name
    }
    return ''
  }

  get selectedItemLongSizzle() {
    if (this.selectedItem && this.selectedItem.item) {
      return this.selectedItem.item.marketingDescription
    }
    return ''
  }

  get selectedItemQuantity() {
    if (this.selectedItem && this.selectedItem.item) {
      const multipler = this.size ? 2 : 1
      return this.selectedItem.item.quantity * multipler
    }
    return ''
  }

  get selectedItemSize() {
    if (this.selectedItem && this.selectedItem.item) {
      return this.selectedItem.item.size
    }
    return ''
  }

  setItemIndex(itemIndex: number) {
    this.itemIndex = itemIndex
  }

  // eslint-disable-next-line class-methods-use-this
  thumbnail(id: number) {
    return `https://assets.steakchop.com/product-thumbnails/${id}.jpg`
  }
}
