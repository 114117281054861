















































































import Vue from 'vue'
import Component from 'vue-class-component'
import SignupModule from '@/store/modules/signup'
import { getModule } from 'vuex-module-decorators'
import { Watch } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class PromotionalCodeEntry extends Vue {
  private promotionalCode = '';

  private valid = false;

  private lazy = true;

  private enterCode = false;

  private promotionalCodeIsInvalid = false;

  signUpModule: SignupModule = getModule(SignupModule, this.$store);

  get hasPromotionalCodeApplied() {
    return this.signUpModule.signupInfo.promotionCode.isValid
  }

  @Watch('promotionalCode')
  private onPromotionalCode() {
    this.promotionalCodeIsInvalid = false
  }

  get promotionalCodeRules() {
    if (!this.promotionalCodeIsInvalid) return []
    return [(v: string) => /^\d{99}([-]|\s*)?(\d{4})?$/.test(v) || 'Invalid']
  }

  async applyCode() {
    this.promotionalCodeIsInvalid = !(await this.signUpModule.AddPromotion(
      this.promotionalCode
    ))
    if (this.signUpModule.signupInfo.promotionCode.isValid) {
      this.promotionalCode = ''
    }

    if (this.$refs.form) {
      (this.$refs.form as Vue & { validate: () => boolean }).validate()
    }
  }
}
