






















import Vue from 'vue'
import Component from 'vue-class-component'

import { Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class NoShip extends Vue {
  @Prop()
  private cityState!: string;

  @Prop()
  private noShip!: boolean;

  private revert() {
    this.$emit('revert-postalcode-change')
  }
}
