

















































































































import Vue from 'vue'
import m from 'moment'
import Component from 'vue-class-component'
import SignupModule from '@/store/modules/signup'
import { getModule } from 'vuex-module-decorators'
import CartSummary from '@/components/signup/Checkout/CartSummary/index.vue'
import { PackSize } from '../../../modules/common'

@Component({
  components: { CartSummary }
})
export default class Receipt extends Vue {
  signUpModule: SignupModule = getModule(SignupModule, this.$store);

  get showTracking() {
    return (
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      process.env.VUE_APP_TRACKING! === 'YES' &&
      this.signUpModule.orderId &&
      this.signUpModule.orderId.length > 1
    )
  }

  get finalPrice() {
    return this.signUpModule.finalPrice
  }

  get brandXTracking() {
    return `https://ctrackr.com/p.ashx?a=380&e=401&t=${this.signUpModule.orderId}`
  }

  // eslint-disable-next-line class-methods-use-this
  get mediaNowTracking() {
    return `https://offers.mowmedia.com/SL3K?adv_sub=SUB_ID=${this.signUpModule.orderId}`
  }

  get emailAddress() {
    return this.signUpModule.signupInfo.customerContactInfo.emailAddress
  }

  get shippingAddress() {
    return this.signUpModule.signupInfo.shippingAddress
  }

  get size() {
    return this.signUpModule.signupInfo.subscriptionConfiguration.packSize ===
      PackSize.Standard
      ? 'Regular'
      : 'Large'
  }

  get frequency() {
    return this.signUpModule.signupInfo.subscriptionConfiguration.frequency
  }

  get businessAddress() {
    if (
      this.signUpModule.signupInfo.billingContact.billingAddress.postalCode
        .length === 5
    ) {
      return this.signUpModule.signupInfo.billingContact.billingAddress
    }
    return null
  }

  get phoneNumber() {
    return this.signUpModule.signupInfo.customerContactInfo.phoneNumber
  }

  get subscriberName() {
    return `${this.signUpModule.signupInfo.customerContactInfo.firstName}
     ${this.signUpModule.signupInfo.customerContactInfo.lastName}`
  }

  get shippingWeek() {
    return this.signUpModule.signupInfo.firstShipmentDate
  }

  get firstDeliveryDay(): string {
    return m(this.shippingWeek).add(2, 'days').format('ddd L')
  }

  get lastDeliveryDay(): string {
    return m(this.shippingWeek).add(4, 'days').format('ddd L')
  }
}
