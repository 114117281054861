









































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import SignupModule from '@/store/modules/signup'
import StripeModule from '@/store/modules/stripe'
import { getModule } from 'vuex-module-decorators'
import { Watch, Prop } from 'vue-property-decorator'
import * as api from '@/apis/signup'
import validator from 'validator'
import { ShippingAddress } from '../../../../modules/common'

@Component({
  components: {}
})
export default class BillingInfo extends Vue {
  signUpModule: SignupModule = getModule(SignupModule, this.$store)

  stripeModule: StripeModule = getModule(StripeModule, this.$store)

  restoringBilling = false

  billingIsSame = true

  localCardHolderName = ''

  streetAddressLine1 = ''

  formHasHadFocus = false

  streetAddressLine2: string | undefined = ''

  city = ''

  state = ''

  postalCode = ''

  stateMask = 'AA'

  mask = '#####'

  cardMessage = ''

  lazy = true

  valid = false

  private get hasFullContactName(): boolean {
    return (
      this.signUpModule.signupInfo.customerContactInfo.firstName !== undefined &&
      this.signUpModule.signupInfo.customerContactInfo.lastName !== undefined
    )
  }

  @Watch('hasFullContactName')
  async onGotContactName(newValue: boolean) {
    if (newValue && this.localCardHolderName === '') {
      this.localCardHolderName = `${this.signUpModule.signupInfo.customerContactInfo.firstName} ${this.signUpModule.signupInfo.customerContactInfo.lastName}`
      await this.signUpModule.AddBillingContact(this.cardHolderName)
    }
  }

  private get restoredFromSessionState(): boolean {
    return this.signUpModule.restoredFromSessionState
  }

  @Watch('restoredFromSessionState')
  onRestoredFromSessionState(newValue: boolean) {
    if (newValue) {
      this.updateDataFromStore()
    }
  }

  created() {
    this.updateDataFromStore()
  }

  private updateDataFromStore() {
    if (this.signUpModule.signupInfo.billingContact.firstName && this.signUpModule.signupInfo.billingContact.lastName) {
      this.cardHolderName = `${this.signUpModule.signupInfo.billingContact.firstName} ${this.signUpModule.signupInfo.billingContact.lastName}`
    }

    let toggledBilling = false
    if (
      this.signUpModule.signupInfo.billingContact.billingAddress.streetAddressLine1 &&
      !validator.isEmpty(this.signUpModule.signupInfo.billingContact.billingAddress.streetAddressLine1)
    ) {
      this.billingIsSame = false
      this.streetAddressLine1 = this.signUpModule.signupInfo.billingContact.billingAddress.streetAddressLine1
      toggledBilling = true
    }
    if (
      this.signUpModule.signupInfo.billingContact.billingAddress.streetAddressLine2 &&
      !validator.isEmpty(this.signUpModule.signupInfo.billingContact.billingAddress.streetAddressLine2)
    ) {
      if (!toggledBilling) {
        this.billingIsSame = false
        toggledBilling = true
      }
      this.streetAddressLine2 = this.signUpModule.signupInfo.billingContact.billingAddress.streetAddressLine2
    }
    if (
      this.signUpModule.signupInfo.billingContact.billingAddress.city &&
      !validator.isEmpty(this.signUpModule.signupInfo.billingContact.billingAddress.city)
    ) {
      if (!toggledBilling) {
        this.billingIsSame = false
        toggledBilling = true
      }
      this.city = this.signUpModule.signupInfo.billingContact.billingAddress.city
    }

    if (
      this.signUpModule.signupInfo.billingContact.billingAddress.state &&
      !validator.isEmpty(this.signUpModule.signupInfo.billingContact.billingAddress.city)
    ) {
      if (!toggledBilling) {
        this.billingIsSame = false
        toggledBilling = true
      }
      this.state = this.signUpModule.signupInfo.billingContact.billingAddress.state
    }

    if (
      this.signUpModule.signupInfo.billingContact.billingAddress.postalCode &&
      !validator.isEmpty(this.signUpModule.signupInfo.billingContact.billingAddress.postalCode)
    ) {
      if (!toggledBilling) {
        this.billingIsSame = false
        toggledBilling = true
      }
      this.postalCode = this.signUpModule.signupInfo.billingContact.billingAddress.postalCode
    }

    this.restoringBilling = toggledBilling
  }

  private get customerSuppliedShippingAddress(): ShippingAddress {
    return this.signUpModule.signupInfo.shippingAddress
  }

  @Watch('postalCode')
  async onPostalCodeChange(newValue: string) {
    if (!this.billingIsSame && newValue && newValue.length === 5) {
      const result = await api.PostalCodeLookup({ postalCode: newValue, isGift: false })
      if (result) {
        this.state = result.state
        this.city = result.city
        this.updateBillingInfo()
      }
    }
  }

  @Watch('billingIsSame')
  async onBillingIsSame(newValue: boolean) {
    if (!newValue) {
      if (this.restoringBilling === false) {
        this.streetAddressLine1 = ''
        this.streetAddressLine2 = ''
        this.city = ''
        this.state = ''
        this.postalCode = ''
        this.valid = false
        await this.signUpModule.ClearBillingAddress()
      }
      this.restoringBilling = false
    } else {
      this.streetAddressLine1 = this.customerSuppliedShippingAddress.address.streetAddressLine1
      this.streetAddressLine2 = this.customerSuppliedShippingAddress.address.streetAddressLine2
      this.city = this.customerSuppliedShippingAddress.address.city
      this.state = this.customerSuppliedShippingAddress.address.state
      this.postalCode = this.customerSuppliedShippingAddress.address.postalCode
      await this.signUpModule.ClearBillingAddress()
    }
  }

  @Watch('customerSuppliedShippingAddress', {
    immediate: true,
    deep: true
  })
  onCustomerSuppliedShippingAddress(newValue: ShippingAddress) {
    if (this.billingIsSame) {
      this.streetAddressLine1 = newValue.address.streetAddressLine1
      this.streetAddressLine2 = newValue.address.streetAddressLine2
      this.city = newValue.address.city
      this.state = newValue.address.state
      this.postalCode = newValue.address.postalCode
    }
  }

  formFocused() {
    this.formHasHadFocus = true
  }

  public get cardHolderName(): string {
    return this.localCardHolderName
  }

  public set cardHolderName(value: string) {
    this.localCardHolderName = value
  }

  private get isCardValid() {
    return this.cardMessage === ''
  }

  private get stripeElements() {
    return this.stripeModule.elements
  }

  private get stripeCard() {
    return this.stripeModule.card
  }

  private get showBilling() {
    return this.billingIsSame
  }

  @Prop()
  private requestValidation!: boolean

  @Watch('stripeCard')
  // eslint-disable-next-line no-undef
  onStripeCard(card: stripe.elements.Element) {
    card.mount('#card-element')
    card.addEventListener('change', this.handleStripeChange)
  }

  @Watch('stripeElements')
  onStripeElements() {
    if (!this.stripeModule.cardInitialized) {
      this.stripeModule.CreateCard({
        hidePostalCode: true,

        iconStyle: 'solid',
        style: {
          base: {
            fontSize: '16px',
            iconColor: 'rgba(0, 0, 0, 0.87)',
            fontFamily: 'Source Sans Pro, sans-serif',
            fontSmoothing: 'antialiased',
            '::placeholder': {
              fontFamily: 'Source Sans Pro, sans-serif'
            }
          },
          invalid: {
            color: '#ff5252',
            iconColor: '#ff5252'
          }
        }
      })
    }
  }

  @Watch('requestValidation')
  private async onRequestValidation(newValue: boolean) {
    if (newValue) {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        this.$emit('validation-status-change', {
          section: 'billing',
          validState: true
        })
      } else {
        this.$emit('validation-status-change', {
          section: 'billing',
          validState: false
        })
      }
    }
  }

  @Watch('valid', { immediate: true })
  async OnValidationChange(newValue: boolean) {
    if (newValue) {
      await this.updateBillingInfo()
    }

    if (this.requestValidation) {
      this.$emit('validation-status-change', {
        section: 'billing',
        validState: newValue
      })
    }
  }

  private async updateBillingInfo() {
    if (this.valid && !this.billingIsSame) {
      this.signUpModule.SetBillingAddress({
        streetAddressLine1: this.streetAddressLine1,
        streetAddressLine2: this.streetAddressLine2 ? this.streetAddressLine2 : '',
        city: this.city,
        state: this.state,
        postalCode: this.postalCode
      })
    }
    if (this.valid) {
      this.signUpModule.AddBillingContact(this.cardHolderName)
    }
  }

  cardHolderNameRules = [
    (v: string) => !!v || 'Cardholder name is required',
    (v: string) => /(\w.+\s).+/.test(v) || 'First and Last name are required.'
  ]

  get streetAddressLine1Rules() {
    if (this.billingIsSame) return []
    return [(v: string) => !!v || 'Street Address is required']
  }

  get stateRules() {
    if (this.billingIsSame) return []
    return [(v: string) => !!v || 'State is required']
  }

  get cityRules() {
    if (this.billingIsSame) return []
    return [(v: string) => !!v || 'City is required']
  }

  get postalCodeRules() {
    if (this.billingIsSame) return []
    return [
      (v: string) => !!v || 'Zip code is required',
      (v: string) => /^\d{5}([-]|\s*)?(\d{4})?$/.test(v) || 'Zip code must have five numbers.'
    ]
  }

  // eslint-disable-next-line class-methods-use-this
  includeStripe(URL: string, callback: () => void) {
    const documentTag = document
    const tag = 'script'
    const object = documentTag.createElement(tag)
    const scriptTag = documentTag.getElementsByTagName(tag)[0]
    object.src = `//${URL}`
    if (callback) {
      object.addEventListener(
        'load',
        () => {
          callback()
        },
        false
      )
    }
    if (scriptTag && scriptTag.parentNode) {
      scriptTag.parentNode.insertBefore(object, scriptTag)
    }
  }

  async mounted() {
    if (!this.stripeModule.stripeInitialized) {
      this.configureStripe()
    } else {
      // eslint-disable-next-line no-undef
      const element = this.stripeModule.card as stripe.elements.Element
      element.mount('#card-element')
      element.addEventListener('change', this.handleStripeChange)
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private configureStripe() {
    if (!this.stripeModule.stripeInitialized) {
      this.stripeModule.InitializeStripe()
    } else {
      // eslint-disable-next-line no-undef
      const element = this.stripeModule.card as stripe.elements.Element
      element.mount('#card-element')
      element.addEventListener('change', this.handleStripeChange)
    }
  }

  // eslint-disable-next-line no-undef
  private handleStripeChange(event: stripe.elements.ElementChangeResponse | undefined) {
    if (event) {
      if (event.error && event.error.message) {
        this.cardMessage = event.error.message
      } else {
        this.cardMessage = 'Unknown error validating your credit card!'
      }
      if (!event.error) {
        this.cardMessage = ''
      }
    }
  }
}
