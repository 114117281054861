


















































































































import { Component, Vue } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import SignupModule from '@/store/modules/signup'

@Component
export default class CustomProteinBanner extends Vue {
  signUpModule: SignupModule = getModule(SignupModule, this.$store)

  Size = false

  get finalPrice() {
    return `$${this.nonDiscountedPrice}`
  }

  get hasDiscount() {
    return this.signUpModule.standardPrice !== this.signUpModule.discountedStandardPrice
  }

  get nonDiscountedPrice() {
    let price = 149.99
    if (this.priceVersion === 'V2') {
      price += 10
    }
    if (this.priceVersion === 'V3') {
      price += 20
    }
    if (this.Size) {
      price = price * 2 - 10
    }
    return price
  }

  // eslint-disable-next-line class-methods-use-this
  get priceVersion() {
    return 'V3'
  }

  get discount() {
    if (this.signUpModule.signupInfo.promotionCode?.dollarAmount) {
      return this.signUpModule.signupInfo.promotionCode.dollarAmount
    }
    return 0
  }

  get discountedPrice() {
    if (this.discount) {
      return this.nonDiscountedPrice - this.discount
    }
    return this.nonDiscountedPrice
  }

  pickProteins() {
    this.$emit('pick-proteins', this.Size)
  }
}
