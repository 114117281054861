


















































































































import Vue from 'vue'
import Component from 'vue-class-component'
import SignupModule from '@/store/modules/signup'
import { getModule } from 'vuex-module-decorators'
import { Watch, Prop } from 'vue-property-decorator'
import * as api from '@/apis/signup'
import validator from 'validator'
import { ShippingEligiblity } from '../../../../modules/common'

@Component({
  components: {}
})
export default class ShippingInfo extends Vue {
  signUpModule: SignupModule = getModule(SignupModule, this.$store);

  streetAddressLine1 = '';

  streetAddressLine2 = '';

  specialInstructions = '';

  localCity = '';

  localState = '';

  localPostalCode = '';

  originalPostalCode = '';

  lazy = true;

  valid = false;

  hadValid = false;

  mask = '#####';

  private get restoredFromSessionState(): boolean {
    return this.signUpModule.restoredFromSessionState
  }

  @Watch('restoredFromSessionState')
  onRestoredFromSessionState(newValue: boolean) {
    if (newValue) {
      this.updateDataFromStore()
    }
  }

  created() {
    this.updateDataFromStore()
  }

  private updateDataFromStore() {
    if (
      this.signUpModule.signupInfo.shippingAddress.specialInstructions &&
      !validator.isEmpty(
        this.signUpModule.signupInfo.shippingAddress.specialInstructions
      )
    ) {
      this.specialInstructions = this.signUpModule.signupInfo.shippingAddress.specialInstructions
    }
    if (
      this.signUpModule.signupInfo.shippingAddress.address.streetAddressLine1 &&
      !validator.isEmpty(
        this.signUpModule.signupInfo.shippingAddress.address.streetAddressLine1
      )
    ) {
      this.streetAddressLine1 = this.signUpModule.signupInfo
        .shippingAddress.address.streetAddressLine1
    }
    if (
      this.signUpModule.signupInfo.shippingAddress.address.streetAddressLine2 &&
      !validator.isEmpty(
        this.signUpModule.signupInfo.shippingAddress.address.streetAddressLine2
      )
    ) {
      this.streetAddressLine2 = this.signUpModule
        .signupInfo.shippingAddress.address.streetAddressLine2
    }
  }

  public get hasZipCode() {
    return this.localPostalCode === ''
  }

  public get hasGoodZipCode() {
    if (this.localPostalCode.length === 5) {
      this.hadValid = true
    }
    return this.hadValid
  }

  public get city() {
    return this.signUpModule.signupInfo.shippingAddress.address.city
  }

  public set city(value) {
    this.localCity = value
  }

  public get state() {
    return this.signUpModule.signupInfo.shippingAddress.address.state
  }

  public set state(value) {
    this.localState = value
  }

  public get postalCode() {
    return this.localPostalCode
  }

  public set postalCode(value) {
    this.localPostalCode = value
  }

  private get customerSuppliedPostalCode(): string {
    if (this.signUpModule.signupInfo.shippingAddress.address.postalCode) {
      return this.signUpModule.signupInfo.shippingAddress.address.postalCode
    }
    return ''
  }

  @Prop()
  private requestValidation!: boolean;

  @Watch('requestValidation')
  private onRequestValidation(newValue: boolean) {
    if (newValue) {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        this.$emit('validation-status-change', {
          section: 'shipping',
          validState: true
        })
      } else {
        this.$emit('validation-status-change', {
          section: 'shipping',
          validState: false
        })
      }
    }
  }

  @Watch('customerSuppliedPostalCode', { immediate: true })
  onCustomerSuppliedPostalCode(newValue: string) {
    this.localPostalCode = newValue
    if (this.originalPostalCode === '' && newValue && newValue.length === 5) {
      this.originalPostalCode = this.localPostalCode
    }
  }

  @Watch('localPostalCode')
  async onLocalPostCodeChange(newValue: string) {
    if (
      newValue &&
      newValue.length === 5 &&
      newValue !== this.originalPostalCode
    ) {
      const result = await api.PostalCodeLookup({
        postalCode: newValue,
        isGift: false
      })
      if (result.shippingEligiblity === ShippingEligiblity.Ineligible) {
        this.localPostalCode = this.originalPostalCode
        this.$emit('shipping-zip-changed-to-ineligible', {
          cityState: `${result.city}, ${result.state}`,
          postalCode: result.postalCode
        })
      } else {
        this.originalPostalCode = result.postalCode
        this.signUpModule.UpdateShippingInfo(result)
      }
    }
  }

  @Watch('valid', { immediate: true })
  async OnValidationChange(newValue: boolean) {
    if (newValue) {
      await this.updateShippingInfo()
    }
    if (this.requestValidation) {
      this.$emit('validation-status-change', {
        section: 'shipping',
        validState: newValue
      })
    }
  }

  private async updateShippingInfo() {
    if (this.valid) {
      this.signUpModule.SetShippingAddress({
        streetAddressLine1: this.streetAddressLine1,
        streetAddressLine2: this.streetAddressLine2,
        specialInstructions: this.specialInstructions
      })
    }
  }

  private async updateCityName(): Promise<void> {
    if (
      this.localCity !== this.signUpModule.signupInfo.shippingAddress.address.city &&
      this.localPostalCode === this.signUpModule.signupInfo.shippingAddress.address.postalCode
    ) {
      await this.signUpModule.UpdateShippingPostalCode(
        { postalCode: this.localPostalCode, overrideCity: this.localCity }
      )
    }
  }

  streetAddressLine1Rules = [
    (v: string) => !!v || 'Street Address is required'
  ];

  postalCodeRules = [
    (v: string) => !!v || 'Zip code is required',
    (v: string) => /^\d{5}([-]|\s*)?(\d{4})?$/.test(v) || 'Zip code must have five numbers.'
  ];
}
