























import { Component, Vue, Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import SignupModule from '@/store/modules/signup'
import ItemReplacementCard from '@/components/signup/ItemSelectorList/ItemReplacementCard/index.vue'
import { PackItem, SubstituteItem } from '@/modules/common'

@Component({ components: { ItemReplacementCard } })
export default class ItemSwapperList extends Vue {
  signUpModule: SignupModule = getModule(SignupModule, this.$store);

  @Prop({ type: Object as () => PackItem })
  private PackItem!: PackItem;

  @Prop()
  private ItemIndex!: number;

  itemToSwapId = '';

  get SubstituteItems(): Array<SubstituteItem> {
    if (this.ItemIndex > -1) {
      return this.signUpModule.SubstituteItemsByIndex(this.ItemIndex)
    }
    return []
  }

  substituteItem(input: { itemIndex: number; newItemId: string }) {
    if (this.$vuetify.breakpoint.xs) {
      if (this.itemToSwapId === input.newItemId) {
        this.itemToSwapId = ''
        this.$emit('pre-substitute-item', { itemIndex: -1, newItemId: '' })
      } else {
        this.itemToSwapId = input.newItemId
        this.$emit('pre-substitute-item', input)
      }
    } else {
      this.$emit('substitute-item', input)
    }
  }
}
