




































import Vue from 'vue'
import Component from 'vue-class-component'
import SignupModule from '@/store/modules/signup'
import { getModule } from 'vuex-module-decorators'

@Component({
  components: {}
})
export default class CustomNavigation extends Vue {
  signUpModule: SignupModule = getModule(SignupModule, this.$store);

  getClassesForStep(stepName: string) {
    let active = false
    switch (stepName) {
      case 'protein-picker':
        active = this.$route.params.pickproteins === 'true'
        break
      case 'item-selector':
        active = this.$route.params.personalize === 'true'
        break
      default:
        break
    }
    if (active) {
      return ['accent', 'circle', 'white--text', 'body-2']
    }

    return ['circle', 'body-2']
  }

  isProteinPickerClickable() {
    return this.$route.params.pickproteins !== 'true'
  }

  proteinPickerHandle() {
    this.$router.push('/shopping/pick-pack/false/true')
  }

  isPickPackClickable() {
    return this.$route.name === 'signup.checkout'
  }

  pickPackHandler() {
    this.$router.push('/shopping/pick-pack')
  }
}
