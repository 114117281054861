

































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import SignupModule from '@/store/modules/signup'
import { getModule } from 'vuex-module-decorators'
import PersonalInfo from '@/components/signup/Checkout/PersonalInfo/index.vue'
import AccountInfo from '@/components/signup/Checkout/AccountInfo/index.vue'
import ShippingInfo from '@/components/signup/Checkout/ShippingInfo/index.vue'
import BillingInfo from '@/components/signup/Checkout/BillingInfo/index.vue'
import PromotionalCodeDisplay from '@/components/signup/Checkout/PromotionalCode/Display/index.vue'
import PromotionalCodeEntry from '@/components/signup/Checkout/PromotionalCode/Entry/index.vue'
import CartSummary from '@/components/signup/Checkout/CartSummary/index.vue'
import SignupIssue from '@/components/signup/AttentionRequired/SignupIssue/index.vue'
import { Watch } from 'vue-property-decorator'
import LogRocket from 'logrocket'
import { PackSize, Frequency } from '../../../modules/common'

@Component({
  components: {
    AccountInfo,
    PersonalInfo,
    ShippingInfo,
    BillingInfo,
    PromotionalCodeDisplay,
    PromotionalCodeEntry,
    CartSummary,
    SignupIssue
  }
})
export default class Checkout extends Vue {
  validState: Array<{ section: string; validState: boolean | undefined }> = [
    { section: 'personal', validState: undefined },
    { section: 'shipping', validState: undefined },
    { section: 'billing', validState: undefined },
    { section: 'account', validState: undefined }
  ];

  signUpModule: SignupModule = getModule(SignupModule, this.$store);

  requestValidation = false;

  updateValidationStatus(input: { section: string; validState: boolean }) {
    const result = this.validState.find((p) => p.section === input.section)
    if (result) {
      result.validState = input.validState
    }
  }

  private get frequency() {
    return this.signUpModule.signupInfo.subscriptionConfiguration.frequency
  }

  private setShippingWeek(value: Date) {
    this.$emit('set-shipping-week', value)
  }

  private shippingZipChangedToEnligible(input: {
    cityState: string;
    postalCode: string;
  }) {
    this.$emit('shipping-zip-changed-to-ineligible', input)
  }

  private shippingZipChangedToDifferent(input: {
    cityState: string;
    postalCode: string;
  }) {
    this.$emit('shipping-zip-changed-to-different', input)
  }

  @Watch('validState', { deep: true, immediate: true })
  async onValidState(
    newValue: Array<{ section: string; validState: boolean | undefined }>
  ) {
    if (
      this.requestValidation &&
      newValue.filter((p) => p.validState === true).length === 4
    ) {
      LogRocket.track('AttemptingPurchase')
      this.$emit('start-signup-process')
    } else if (
      newValue.filter((p) => p.validState === false && p.section === 'account')
        .length === 1
    ) {
      this.$vuetify.goTo(this.$refs.account as HTMLElement)
      this.requestValidation = false
    } else if (
      newValue.filter((p) => p.validState === false && p.section === 'personal')
        .length === 1
    ) {
      this.$vuetify.goTo(this.$refs.personal as HTMLElement)
      this.requestValidation = false
    } else if (
      newValue.filter((p) => p.validState === false && p.section === 'shipping')
        .length === 1
    ) {
      this.$vuetify.goTo(this.$refs.shipping as HTMLElement)
      this.requestValidation = false
    } else if (
      newValue.filter((p) => p.validState === false && p.section === 'billing')
        .length === 1
    ) {
      this.$vuetify.goTo(this.$refs.billing as HTMLElement)
      this.requestValidation = false
    }

    if (newValue.find((p) => p.validState === undefined) === undefined) {
      this.requestValidation = false
    }
  }

  get requestedValidation(): boolean {
    return this.requestValidation
  }

  get areThereErrors() {
    return this.validState.find((p) => p.validState === false) !== undefined
  }

  get isFormReady(): boolean {
    return !(this.validState.filter((p) => p.validState === true).length === 4)
  }

  updatePackSize(value: PackSize) {
    this.$emit('pack-size-changed', value)
  }

  updateFrequency(value: Frequency) {
    this.$emit('frequency-changed', value)
  }

  startSignupProcess() {
    this.validState[0].validState = undefined
    this.validState[1].validState = undefined
    this.validState[2].validState = undefined
    this.validState[3].validState = undefined
    this.requestValidation = true
  }
}
