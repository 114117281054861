























































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import SignupModule from '@/store/modules/signup'
import { CommonItemConfiguration, Frequency, PackSize } from '@/modules/common'
import PackDetailCard from '@/components/signup/CommonPackList/PackDetailCard/index.vue'
import PackDetailMobileCard from '@/components/signup/CommonPackList/PackDetailMobileCard/index.vue'
import sum from 'lodash/sum'
import sortBy from 'lodash/sortBy'

@Component({ components: { PackDetailCard, PackDetailMobileCard } })
export default class CommonPackCard extends Vue {
  signUpModule: SignupModule = getModule(SignupModule, this.$store)

  @Prop({ type: Object as () => CommonItemConfiguration })
  private CommonItemConfiguration!: CommonItemConfiguration

  @Prop()
  private homePageMode!: boolean

  @Watch('signUpModule.signupInfo.subscriptionConfiguration.packSize', {
    deep: true
  })
  onPackSizeChange(newValue: PackSize) {
    if (this.CommonItemConfiguration.id === this.signUpModule.selectedCommonItemConfiguration.id) {
      this.Size = newValue === PackSize.Large
    }
  }

  get isInHomePageMode() {
    return this.homePageMode
  }

  get hasDiscount() {
    return this.signUpModule.standardPrice !== this.signUpModule.discountedStandardPrice
  }

  get ValueIcon() {
    if (this.CommonItemConfiguration.name === 'The Whole Shebang') {
      return 'utensils'
    }
    if (this.CommonItemConfiguration.name === "Butcher's Feast") {
      return 'star'
    }
    if (this.CommonItemConfiguration.name === 'Land & Sea') {
      return 'glass-cheers'
    }
    return null
  }

  get ValueProp() {
    if (this.CommonItemConfiguration.name === 'The Whole Shebang') {
      return 'Most Variety'
    }
    if (this.CommonItemConfiguration.name === "Butcher's Feast") {
      return 'Most Popular'
    }
    if (this.CommonItemConfiguration.name === 'Land & Sea') {
      return 'Fine Dining'
    }
    return null
  }

  @Watch('CommonItemConfiguration')
  onCommonItemConfigurationChanged(newValue: CommonItemConfiguration, oldValue: CommonItemConfiguration) {
    if (newValue.id && oldValue.id === '' && this.$vuetify.breakpoint.xs) {
      if (this.signUpModule.selectedCommonItemConfiguration.id === newValue.id) {
        this.Frequency = this.signUpModule.signupInfo.subscriptionConfiguration.frequency
      }
    }
  }

  frequency: Frequency = Frequency.Unknown

  size = false

  itemIndex = 0

  theViewPackDetailsModal = false

  theViewPackDetailsMobile = false

  get sizeVerbose() {
    if (this.size) {
      return 'Perfect for families with 4-5 people!'
    }
    return 'Great for families with 1-4 people!'
  }

  get viewPackDetailsModal() {
    return this.theViewPackDetailsModal
  }

  set viewPackDetailsModal(value: boolean) {
    this.itemIndex = 0
    this.theViewPackDetailsModal = value
  }

  get viewPackDetailsMobile() {
    return this.theViewPackDetailsMobile
  }

  set viewPackDetailsMobile(value: boolean) {
    this.itemIndex = 0
    this.theViewPackDetailsMobile = value
  }

  get Size() {
    return this.size
  }

  set Size(value: boolean) {
    this.size = value
  }

  get Frequency() {
    return this.frequency
  }

  set Frequency(value: Frequency) {
    this.frequency = value
  }

  get finalPrice() {
    if (this.size) {
      return `$${this.signUpModule.largePrice}`
    }
    return `$${this.signUpModule.standardPrice}`
  }

  get discountedPrice() {
    if (this.size) {
      return `$${this.signUpModule.discountedLargePrice}`
    }
    return `$${this.signUpModule.discountedStandardPrice}`
  }

  get items() {
    const theItems = sortBy(this.CommonItemConfiguration.items, (p) => p.displayOrder)
    if (
      this.signUpModule.signupInfo.promotionCode.isValid &&
      this.signUpModule.signupInfo.promotionCode.freeProducts &&
      this.signUpModule.signupInfo.promotionCode.freeProducts.length === 1
    ) {
      theItems.push(this.signUpModule.signupInfo.promotionCode.freeProducts[0])
    }
    return theItems
  }

  get pricePerFive() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const totalServings = sum(this.CommonItemConfiguration.items.map((p) => (p.item ? p.item.calcWeight : 0))) / 5
    if (this.size) {
      return (this.signUpModule.largePrice / (totalServings * 2)).toFixed(2)
    }

    return (this.signUpModule.standardPrice / totalServings).toFixed(2)
  }

  toggleDetails() {
    if (this.viewPackDetailsMobile === false) {
      this.$vuetify.goTo(this.$refs.viewLink as HTMLElement)
      this.viewPackDetailsMobile = true
    } else {
      this.viewPackDetailsMobile = false
      this.$emit('goto-mobile-images')
    }
  }

  changeIndex(index: number) {
    if (index < 0) {
      this.itemIndex = this.items.length - 1
    } else if (index === this.items.length) {
      this.itemIndex = 0
    } else {
      this.itemIndex = index
    }
  }

  advance() {
    this.changeIndex(this.itemIndex + 1)
  }

  retreat() {
    this.changeIndex(this.itemIndex - 1)
  }

  selectCommonItemConfiguration(input: { id: string; personalize: boolean; size: PackSize; frequency: Frequency }) {
    this.$emit('common-item-configuration-selected', input)
  }

  purchase(id: string) {
    this.$emit('common-item-configuration-selected', {
      id,
      frequency: this.signUpModule.signupInfo.subscriptionConfiguration.frequency,
      personalize: false,
      size: this.size ? PackSize.Large : PackSize.Standard
    })
  }

  personalize(id: string) {
    this.$emit('common-item-configuration-selected', {
      id,
      frequency: this.signUpModule.signupInfo.subscriptionConfiguration.frequency,
      size: this.size ? PackSize.Large : PackSize.Standard,
      personalize: true
    })
  }

  get thumbnail() {
    return `https://assets.steakchop.com/pack-thumbnails/${this.CommonItemConfiguration.id}.jpg`
  }

  async mounted() {
    if (this.Frequency === Frequency.Unknown) {
      this.Frequency = Frequency.FourWeeks
    }
  }
}
