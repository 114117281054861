



































import { Component, Vue, Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import SignupModule from '@/store/modules/signup'
import ItemReplacementCard from '@/components/signup/ItemSelectorList/ItemReplacementCard/index.vue'
import { PackItem } from '@/modules/common'

@Component({ components: { ItemReplacementCard } })
export default class ItemSwapper extends Vue {
  signUpModule: SignupModule = getModule(SignupModule, this.$store);

  @Prop({ type: Object as () => PackItem })
  private PackItem!: PackItem;

  get ItemName() {
    if (this.PackItem && this.PackItem.item) {
      return this.PackItem.item.name
    }
    return null
  }

  get ItemQuantity() {
    if (this.PackItem && this.PackItem.item) {
      return this.PackItem.item.quantity
    }
    return null
  }

  get ItemDescription() {
    if (this.PackItem && this.PackItem.item) {
      return this.PackItem.item.marketingDescription
    }
    return null
  }

  get ItemShortDescription() {
    if (this.PackItem && this.PackItem.item) {
      return this.PackItem.item.marketingDescriptionShort
    }
    return null
  }

  get ItemSize() {
    if (this.PackItem && this.PackItem.item) {
      return this.PackItem.item.size
    }
    return null
  }

  get thumbnail() {
    if (this.PackItem) {
      return `https://assets.steakchop.com/product-thumbnails/${this.PackItem.id}.jpg`
    }
    return undefined
  }

  get imageHeight() {
    let height = 0

    if (this.$vuetify.breakpoint.smAndDown) {
      return undefined
    }
    height = 160

    return `${height}px`
  }

  get imageWidth() {
    if (this.$vuetify.breakpoint.mdAndUp) {
      return undefined
    }
    return `${(this.$vuetify.breakpoint.width * 0.95) / 2}px`
  }
}
