














import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component({})
export default class CommonQuestions extends Vue {
  // eslint-disable-next-line class-methods-use-this
  get questions() {
    return [
      {
        Question:
          'How much do packs cost and what’s included? Do all packs cost the same amount?',
        Answer:
          'Choose from our chef-curated packs or customize your own. Either way, the price is always the same -- $159.00 per delivery and shipping is always free! Got a large family or big eaters? Double your order and save $10. It’s that simple! '
      },
      {
        Question: 'Where does your meat and seafood come from?',
        Answer:
          'We’ve spent decades forging and fostering direct relationships with America’s best-in-class ranchers, producers, and fishmongers to deliver the best quality proteins available. All SteakChop meat is 100% American born, raised, and harvested, and our wild-caught seafood is sourced exclusively from trusted, certified fisheries.'
      },
      {
        Question:
          'How are your packs shipped and what is the delivery process?',
        Answer:
          'SteakChop delivers nationwide to the continental U.S.  Deliveries are packed with enough dry ice to stay frozen for 12 hours after they arrive so there’s no need to rush home, and all items are individually vacuum packed for easy storage and portioning to ensure your delivery experience meets the same high-quality standards as our proteins.'
      },
      {
        Question: 'Can I make changes to my schedule and box?',
        Answer:
          'You choose your first delivery date and how often you’d like to receive deliveries moving forward. Rescheduling or canceling is super easy, with no hidden fees. And, you can change the contents of your pack prior to each delivery.  Bottom line, you’re in control! You choose what you want, when you want it, hassle- and worry-free.'
      }
    ]
  }
}
