
































import { Component, Vue, Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import SignupModule from '@/store/modules/signup'
import CommonPackCard from '@/components/signup/CommonPackList/CommonPackCard/index.vue'
import MobilePackImage from '@/components/signup/MobilePackImage/index.vue'
import FrequencySelector from '@/components/signup/FrequencySelector/index.vue'
import {
  CommonItemConfiguration, ShippingCenter, PackSize, Frequency
} from '@/modules/common'

@Component({ components: { CommonPackCard, MobilePackImage, FrequencySelector } })
export default class CommonPackList extends Vue {
  signUpModule: SignupModule = getModule(SignupModule, this.$store);

  @Prop()
  private homePageMode!: boolean;

  get ready() {
    return this.signUpModule.itemsAttachedToConifgs
  }

  get AvailableCommonConfigurations(): Array<CommonItemConfiguration> {
    if (this.ready) {
      return this.signUpModule.PurchasableCommonConfigurations
    }
    return []
  }

  get imageHeight() {
    return `${(this.$vuetify.breakpoint.width / 2) * 0.6666666666666667}px`
  }

  mounted() {
    const products: Array<{
      name: string;
      id: string;
      price: string;
      brand: string;
      catergory: string;
      variant: string;
      position: number;
    }> = []
    this.AvailableCommonConfigurations.forEach((element) => {
      products.push({
        name: element.name,
        id: element.id,
        brand: 'SteakChop',
        price: '169.99',
        catergory: 'Popular Packs',
        position: this.AvailableCommonConfigurations.indexOf(element),
        variant:
          element.shippingCenter === ShippingCenter.WestCoastCenter1
            ? 'Crystal Creek' : element.shippingCenter === ShippingCenter.EastCoastCenter2 ? 'Heeps'
              : 'Schiffs'
      })
    })

    if (this.$vuetify.breakpoint.xs && !this.signUpModule.IsCommonItemConfigurationSelected &&
    this.AvailableCommonConfigurations.length > 0 &&
    this.signUpModule.SelectedItems.length === 0) {
      this.viewCommonItemConfiguration({
        id: this.AvailableCommonConfigurations[0].id
      })
    }
    const output = { ecommerce: { impressions: products } }
    output.ecommerce.impressions = products
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((Vue as any).gtm) {
      (Vue as any).gtm.trackEvent(output)
    }
  }

  viewCommonItemConfiguration(input: { id: string}) {
    this.$emit('common-item-configuration-view', input)
  }

  selectCommonItemConfiguration(input: { id: string;
  personalize: boolean;
  size: PackSize;
  frequency: Frequency; }) {
    this.$emit('common-item-configuration-selected', input)
  }

  updateFrequency(input: number) {
    this.$emit('frequency-changed', input)
  }
}
