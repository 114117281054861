



































































import {
  Component, Vue, Prop, Watch
} from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import SignupModule from '@/store/modules/signup'
import ItemReplacementCard from '@/components/signup/ItemSelectorList/ItemReplacementCard/index.vue'
import { PackItem, SubstituteItem } from '@/modules/common'

@Component({ components: { ItemReplacementCard } })
export default class ItemCard extends Vue {
  signUpModule: SignupModule = getModule(SignupModule, this.$store);

  @Prop({ type: Object as () => PackItem })
  private PackItem!: PackItem;

  @Prop()
  private ItemIndex!: number;

  @Prop()
  private SwapIndex!: number;

  @Prop()
  private showSwapper!: boolean;

  pulsing = false;

  @Watch('showSwapper', { immediate: true })
  onShowSwapperChanged(newValue: boolean) {
    if (newValue === true) {
      this.pulsing = false
    }
  }

  @Watch('PackItem.id', { immediate: true, deep: true })
  onPackItemChanged(newValue: string, oldValue: string) {
    if (oldValue !== undefined && !this.showSwapper) {
      this.pulsing = true
    }
  }

  get imageHeight() {
    let height = 0

    if (this.$vuetify.breakpoint.smAndDown) {
      return undefined
    }
    height = 160

    return `${height}px`
  }

  get imageWidth() {
    if (this.$vuetify.breakpoint.mdAndUp) {
      return undefined
    }
    return `${(this.$vuetify.breakpoint.width * 0.95) / 2}px`
  }

  get showReplacements() {
    return this.SwapIndex === this.ItemIndex
  }

  get thumbnail() {
    return `https://assets.steakchop.com/product-thumbnails/${this.PackItem.id}.jpg`
  }

  get SubstituteItems(): Array<SubstituteItem> {
    return this.signUpModule.SubstituteItemsByIndex(this.ItemIndex)
  }

  substituteItem(input: { itemIndex: number; newItemId: string }) {
    this.$emit('substitute-item', input)
  }
}
