



















































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import SignupModule from '@/store/modules/signup'
import { getModule } from 'vuex-module-decorators'
import CommonPackList from '@/components/signup/CommonPackList/index.vue'
import ItemSelectorList from '@/components/signup/ItemSelectorList/index.vue'
import CustomProteinBanner from '@/components/signup/CustomProteinBanner2/index.vue'
import CustomProteinSelector from '@/components/signup/CustomProteinSelector/index.vue'
import Quotes from '@/components/signup/Quotes/index.vue'
import { ProteinType, PackSize, Frequency } from '@/modules/common'
import CommonQuestions from '@/components/signup/CommonQuestions/index.vue'

@Component({
  components: {
    CommonPackList,
    CustomProteinBanner,
    ItemSelectorList,
    CustomProteinSelector,
    Quotes,
    CommonQuestions
  }
})
export default class PickYourPack extends Vue {
  signUpModule: SignupModule = getModule(SignupModule, this.$store);

  isPickingProteins = false;

  isPersonalizing = false;

  @Prop()
  personalize!: boolean;

  @Prop()
  private cameFromCheckout!: boolean;

  @Prop()
  pickproteins!: boolean;

  @Watch('pickproteins')
  onPickingProteins(value: string) {
    this.isPickingProteins = value === 'true'
  }

  @Watch('personalize')
  onPersonalizng(value: string) {
    this.isPersonalizing = value === 'true'
  }

  get showRenforcment() {
    return this.isPersonalizing === false && this.isPickingProteins === false
  }

  get showCommon() {
    return this.isPickingProteins === false && this.isPersonalizing === false
  }

  get shouldScroll() {
    return (
      this.signUpModule.SelectedItems.length > 6 ||
      this.$vuetify.breakpoint.smAndDown
    )
  }

  processCancel() {
    if (this.cameFromCheckout) {
      this.$emit('done-selecting-items')
    } else {
      this.$emit('cancel-customization')
    }
  }

  commonItemConfigurationSelected(input: {
    id: string;
    personalize: boolean;
    size: PackSize;
    frequency: Frequency;
  }) {
    this.$emit('common-item-configuration-selected', input)
  }

  mounted() {
    this.isPersonalizing = this.$router.currentRoute.params.personalize === 'true'
    this.isPickingProteins = this.$router.currentRoute.params.pickproteins === 'true'
  }

  proteinsSelected(input: Array<ProteinType>) {
    this.$emit('proteins-selected', input)
  }

  pickProteins(size: boolean) {
    this.$emit('pick-proteins', size)
  }

  substituteItem(input: { itemIndex: number; newItemId: string }) {
    this.$emit('substitute-item', input)
  }

  viewCommonItemConfiguration(input: { id: string }) {
    this.$emit('common-item-configuration-view', input)
  }

  updateFrequency(input: number) {
    this.$emit('frequency-changed', input)
  }
}
