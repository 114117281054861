































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import SignupModule from '@/store/modules/signup'
import { getModule } from 'vuex-module-decorators'
import { Watch } from 'vue-property-decorator'
import { PackSize } from '@/modules/common'
import { sum } from 'lodash'

@Component({
  components: {}
})
export default class BasicsSizeAndFrequency extends Vue {
  signUpModule: SignupModule = getModule(SignupModule, this.$store);

  private packSize = PackSize.Unknown;

  private frequency: Array<number> = [4, 6, 8];

  private edit = false;

  private get restoredFromSessionState(): boolean {
    return this.signUpModule.restoredFromSessionState
  }

  @Watch('restoredFromSessionState')
  onRestoredFromSessionState(newValue: boolean) {
    if (newValue) {
      this.updateDataFromStore()
    }
  }

  created() {
    this.updateDataFromStore()
  }

  checkout() {
    this.$router.push('/shopping/checkout')
  }

  get isLarge() {
    return this.packSize === PackSize.Large
  }

  get hasDiscount() {
    return this.signUpModule.standardPrice !==
    this.signUpModule.discountedStandardPrice
  }

  get isRegular() {
    return this.packSize === PackSize.Standard
  }

  selectRegular() {
    this.signUpModule.SingleDown()
    this.packSize = PackSize.Standard
  }

  selectLarge() {
    this.signUpModule.DoubleUp()
    this.packSize = PackSize.Large
  }

  get largePrice() {
    return this.signUpModule.largePrice
  }

  get standardPrice() {
    return this.signUpModule.standardPrice
  }

  get discountedLargePrice() {
    return this.signUpModule.discountedLargePrice
  }

  get discountedStandardPrice() {
    return this.signUpModule.discountedStandardPrice
  }

  private updateDataFromStore() {
    this.packSize = this.signUpModule.signupInfo.subscriptionConfiguration.packSize
  }

  get Frequency() {
    return this.signUpModule.signupInfo.subscriptionConfiguration.frequency
  }

  set Frequency(value: number) {
    if (value) {
      this.$emit('frequency-changed', value)
      this.edit = false
    }
  }

  get pricePerFiveStd() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const totalServings = sum(
      this.signUpModule.selectedItems.map((p) => (p.item
        ? p.item.calcWeight * p.shippingQuantity : 0))
    ) / 5

    if (this.signUpModule.signupInfo.subscriptionConfiguration.packSize === PackSize.Large) {
      return (
        this.discountedStandardPrice /
        (totalServings / 2)
      ).toFixed(2)
    }
    return (
      this.discountedStandardPrice /
        (totalServings)
    ).toFixed(2)
  }

  get pricePerFiveDbl() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const totalServings = sum(
      this.signUpModule.selectedItems.map((p) => (p.item
        ? p.item.calcWeight * p.shippingQuantity : 0))
    ) / 5

    if (this.signUpModule.signupInfo.subscriptionConfiguration.packSize === PackSize.Large) {
      return (
        this.discountedLargePrice /
        (totalServings)
      ).toFixed(2)
    }

    return (
      this.discountedLargePrice /
        (totalServings * 2)
    ).toFixed(2)
  }
}

