








































































import Vue from 'vue'
import Component from 'vue-class-component'
import SignupModule from '@/store/modules/signup'
import { getModule } from 'vuex-module-decorators'
import validator from 'validator'
import { Watch, Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class PersonalInfo extends Vue {
  signUpModule: SignupModule = getModule(SignupModule, this.$store);

  private localFirstName = '';

  private localLastName = '';

  private localMobilePhone = '';

  lazy = true;

  valid = false;

  private get restoredFromSessionState(): boolean {
    return this.signUpModule.restoredFromSessionState
  }

  private get signupLastName() {
    return this.signUpModule.signupInfo.customerContactInfo.lastName
  }

  @Watch('signupLastName')
  onLastNameChanged() {
    if (this.localLastName !== this.signUpModule.signupInfo.customerContactInfo.lastName) {
      this.localLastName = this.signUpModule.signupInfo.customerContactInfo.lastName!
    }
    if (this.localFirstName !== this.signUpModule.signupInfo.customerContactInfo.firstName) {
      this.localFirstName = this.signUpModule.signupInfo.customerContactInfo.firstName!
    }
  }

  @Watch('restoredFromSessionState')
  onRestoredFromSessionState(newValue: boolean) {
    if (newValue) {
      this.updateDataFromStore()
    }
  }

  created() {
    this.updateDataFromStore()
  }

  mounted() {
    if (this.firstName === '') {
      (this.$refs.firstName as any).focus()
    } else
    if (this.lastName === '') {
      (this.$refs.lastName as any).focus()
    } else
    if (this.mobilePhone === '') {
      (this.$refs.mobilePhone as any).focus()
    }
  }

  private updateDataFromStore() {
    if (
      this.signUpModule.signupInfo.customerContactInfo.firstName &&
      this.signUpModule.signupInfo.customerContactInfo.lastName
    ) {
      this.localFirstName = this.signUpModule.signupInfo.customerContactInfo.firstName
      this.localLastName = this.signUpModule.signupInfo.customerContactInfo.lastName
    }
    if (
      this.signUpModule.signupInfo.customerContactInfo.phoneNumber &&
      validator.isMobilePhone(
        this.signUpModule.signupInfo.customerContactInfo.phoneNumber,
        'en-US'
      )
    ) {
      this.mobilePhone = this.signUpModule.signupInfo.customerContactInfo.phoneNumber
    }
  }

  @Prop()
  private requestValidation!: boolean;

  @Watch('requestValidation')
  private async onRequestValidation(newValue: boolean) {
    if (newValue) {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        await this.updatePersonalInfo()
        this.$emit('validation-status-change', {
          section: 'personal',
          validState: true
        })
      } else {
        this.$emit('validation-status-change', {
          section: 'personal',
          validState: false
        })
      }
    }
  }

  @Watch('valid', { immediate: true })
  async OnValidationChange(newValue: boolean) {
    if (newValue) {
      await this.updatePersonalInfo()
    }

    if (this.requestValidation) {
      this.$emit('validation-status-change', {
        section: 'personal',
        validState: newValue
      })
    }
  }

  private async updatePersonalInfo() {
    if (this.valid) {
      await this.signUpModule.AddCustomerName(
        { firstName: this.localFirstName, lastName: this.localLastName }
      )
      await this.signUpModule.AddCustomerMobilePhone(this.mobilePhone)
      // await this.signUpModule.AddCustomerPassword(this.localPassword);
      // if (this.localEmailAddress && validator.isEmail(this.localEmailAddress)) {
      //   await this.signUpModule.UpdateUsername(this.localEmailAddress);
      // } else if (this.emailAddress) {
      //   await this.signUpModule.UpdateUsername(this.emailAddress);
      // }
    }
  }

  public get firstName() {
    return this.localFirstName
  }

  public set firstName(value: string) {
    this.localFirstName = value
  }

  public get lastName() {
    return this.localLastName
  }

  public set lastName(value: string) {
    this.localLastName = value
  }

  public get mobilePhone() {
    if (this.localMobilePhone.length === 10) {
      return `(${this.localMobilePhone.substr(0, 3)}) ${this.localMobilePhone.substr(3, 3)}-${this.localMobilePhone.substr(6)}`
    }
    return this.localMobilePhone
  }

  public set mobilePhone(value) {
    let cleansed = value
    if (cleansed) {
      cleansed = value.replace(/[^0-9]/g, '')
      if (cleansed.startsWith('1')) {
        cleansed = cleansed.substring(1)
      }

      if (cleansed.length === 10) {
        cleansed = `(${cleansed.substr(0, 3)}) ${cleansed.substr(3, 3)}-${cleansed.substr(6)}`
      }
    }
    this.localMobilePhone = cleansed
  }

  firstNameRules = [
    (v: string) => !!v || 'First name is required'
  ];

  lastNameRules = [
    (v: string) => !!v || 'Last name is required'
  ];

  passwordRules = [
    (v: string) => !!v || 'Password is required',
    (v: string) => v.length >= 8 || 'Password must be at least 8 characters.'
  ];

  mobilePhoneRules = [
    (v: string) => !!v || 'Mobile phone is required',
    (v: string) => /^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/.test(v) ||
      'Mobile phone appears to be invalid.'
  ];
}
