




































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import SignupModule from '@/store/modules/signup'
import ItemCard from '@/components/signup/ItemSelectorList/ItemCard/index.vue'
import CustomNavigation from '@/components/signup/CustomNavigation/CustomNavigation.vue'
import ItemReplacementCard from '@/components/signup/ItemSelectorList/ItemReplacementCard/index.vue'
import ItemSwapperList from '@/components/signup/ItemSelectorList/ItemSwapperList/index.vue'
import ItemSwapperHeader from '@/components/signup/ItemSelectorList/ItemSwapperHeader/index.vue'
import { PackItem, SubstituteItem } from '@/modules/common'

@Component({
  components: {
    ItemCard,
    ItemReplacementCard,
    CustomNavigation,
    ItemSwapperList,
    ItemSwapperHeader
  }
})
export default class ItemSelectorList extends Vue {
  signUpModule: SignupModule = getModule(SignupModule, this.$store);

  subsituteItems: Array<SubstituteItem> = [];

  substituteIndex = -1;

  confirmSwap = false;

  swappedItemName = '';

  swappedItemId = '';

  swappedItemIndex = -1;

  enableSwapping = true;

  preSubstitute: { itemIndex: number; newItemId: string } = {
    itemIndex: -1,
    newItemId: ''
  };

  get swapper() {
    return this.confirmSwap
  }

  @Watch('swapper')
  onEnabledSwapping(newValue: boolean) {
    if (newValue === false) {
      this.$emit('substitute-item',
        { itemIndex: this.swappedItemIndex, newItemId: this.swappedItemId })

      this.swappedItemName = ''
      this.swappedItemId = ''
      this.swappedItemIndex = -1
    }
  }

  get swapCongrats() {
    // eslint-disable-next-line radix
    switch (Number.parseInt(this.swappedItemId) % 10) {
      case 0:
        return 'One of our favorites!'
      case 1:
        return 'Great call!'
      case 2:
        return 'What a swap!'
      case 3:
        return 'Looking like the perfect pack!'
      case 4:
        return 'Delicious decision!'
      case 5:
        return 'One word- Tasty!'
      case 6:
        return 'One word- Tasty!'
      case 7:
        return 'You\'re making me hungry!'
      case 8:
        return 'Impressive choice!'
      case 9:
        return 'We love that swap!'
      default:
        return 'Nice swap!'
    }
  }

  get packProteins() {
    return this.signUpModule.selectedProteins
  }

  get swapperMaxHeight() {
    if (this.$vuetify.breakpoint.smAndDown) {
      return 'undefined'
    }
    return `${this.$vuetify.breakpoint.height - 169}px`
  }

  get PackItems(): Array<PackItem> {
    return this.signUpModule.SelectedItems
  }

  get IsCommonPackConfiguration() {
    return this.signUpModule.IsCommonItemConfigurationSelected
  }

  get packName() {
    return this.signUpModule.selectedCommonItemConfiguration.name
  }

  get SelectedSubstituteItem() {
    if (this.substituteIndex !== -1) {
      return this.PackItems[this.substituteIndex]
    }
    return null
  }

  processBack() {
    this.$router.push('/shopping/pick-pack/')
  }

  cancel() {
    this.substituteIndex = -1
    this.preSubstitute.newItemId = ''
    this.preSubstitute.itemIndex = -1
    this.$emit('cancel-customization')
  }

  showSubstitutes(itemIndex: number) {
    if (itemIndex === this.substituteIndex) {
      this.substituteIndex = -1
    } else {
      this.substituteIndex = itemIndex
    }
  }

  preSubstituteItem(input: { itemIndex: number; newItemId: string }) {
    this.preSubstitute = input
  }

  substituteItem(input: { itemIndex: number; newItemId: string }) {
    this.substituteIndex = -1
    const theItem = this.signUpModule.items.filter(
      (p) => p.id === input.newItemId
    )[0]
    // eslint-disable-next-line radix
    this.swappedItemId = theItem.id
    this.swappedItemName = theItem.name
    this.swappedItemIndex = input.itemIndex

    this.confirmSwap = true
    this.enableSwapping = false
    this.preSubstitute.newItemId = ''
    this.preSubstitute.itemIndex = -1
    const target = (this.$refs[`pack_item_${this.swappedItemIndex}`] as Element[])[0]
    this.$nextTick(
      this.$scrollTo({
        element: target
      })
    )
  }
}
