






























import { Component, Vue, Prop } from 'vue-property-decorator'
import SignupModule from '@/store/modules/signup'
import { getModule } from 'vuex-module-decorators'

@Component({ components: {} })
export default class Resume extends Vue {
  signUpModule: SignupModule = getModule(SignupModule, this.$store);

  @Prop()
  subscriptionId: string | undefined;

  @Prop()
  promotionCode: string | undefined;

  @Prop()
  giftCustomer: string | undefined;

  @Prop()
  shouldResume: string | undefined;

  loaded = false;

  get firstName() {
    return this.signUpModule.signupInfo.customerContactInfo.firstName
  }

  get cta() {
    if (this.isGiftRecipient) {
      return 'Start My Order'
    }
    return 'Reactivate My Account'
  }

  get isGiftRecipient() {
    return this.giftCustomer === 'true'
  }

  start() {
    this.$router.push('/shopping/resume')
  }

  async mounted() {
    await this.signUpModule.Initialize()
    await this.signUpModule.GetReactivationInfo(this.subscriptionId!)
    this.loaded = true
    if (this.promotionCode && this.promotionCode !== '') {
      await this.signUpModule.AddPromotion(this.promotionCode)
    }
    if (this.shouldResume && this.shouldResume === 'true') {
      this.$router.push('/shopping/resume')
    }
  }
}
