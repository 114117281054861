





































































































import Vue from 'vue'
import Component from 'vue-class-component'
import SignupModule from '@/store/modules/signup'
import { getModule } from 'vuex-module-decorators'
import { Prop, Watch } from 'vue-property-decorator'
import validator from 'validator'
import { VFBLoginScope as VFacebookLoginScope } from 'vue-facebook-login-component'
import { SubscriberStatus } from '@/modules/common'

@Component({
  components: { VFacebookLoginScope }
})
export default class AccountInfo extends Vue {
  signUpModule: SignupModule = getModule(SignupModule, this.$store)

  private localPassword = ''

  private localEmailAddress: string | undefined = ''

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private scope: any

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private fb: any

  // eslint-disable-next-line class-methods-use-this
  get fbKey() {
    return process.env.VUE_APP_FACEBOOK_API
  }

  fbDisconnect() {
    this.signUpModule.SetThirdPartyCredentials({ id: '', provider: '' })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSdkInit(sdk: { FB: any; scope: any }) {
    this.fb = sdk.FB
    this.scope = sdk.scope
  }

  fbLogin() {
    this.fb.login(
      (loginResponse: { status: string }) => {
        if (loginResponse.status === 'connected') {
          this.fb.api(
            '/me',
            { fields: 'email, last_name, first_name' },
            async (apiResponse: { email: string; last_name: string; first_name: string; id: string }) => {
              const userStatus = await this.signUpModule.UpdateUsername(apiResponse.email)
              if (userStatus === SubscriberStatus.None) {
                await this.signUpModule.AddCustomerName({
                  firstName: apiResponse.first_name,
                  lastName: apiResponse.last_name
                })

                await this.signUpModule.SetThirdPartyCredentials({
                  id: apiResponse.id,
                  provider: 'fb'
                })
                try {
                  this.fb.logout()
                  // eslint-disable-next-line no-empty
                } catch {}
              }
            }
          )
        }
      },
      {
        scope: 'email',
        return_scopes: true
      }
    )
  }

  private get isFaceBookConnected(): boolean {
    return this.signUpModule.IsFaceBookConnected
  }

  public get emailAddress(): string | undefined {
    return this.localEmailAddress
  }

  public set emailAddress(value: string | undefined) {
    this.localEmailAddress = value
  }

  lazy = true

  valid = false

  private get restoredFromSessionState(): boolean {
    return this.signUpModule.restoredFromSessionState
  }

  mounted() {
    if (this.showAccountInfo === false) {
      this.valid = true
    }
  }

  @Prop()
  private requestValidation!: boolean

  @Watch('requestValidation')
  private async onRequestValidation(newValue: boolean) {
    if (newValue) {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        await this.updateAccountInfo()
        this.$emit('validation-status-change', {
          section: 'account',
          validState: true
        })
      } else {
        this.$emit('validation-status-change', {
          section: 'account',
          validState: false
        })
      }
    }
  }

  @Watch('valid', { immediate: true })
  async OnValidationChange(newValue: boolean) {
    if (newValue) {
      await this.updateAccountInfo()
    }

    if (this.requestValidation) {
      this.$emit('validation-status-change', {
        section: 'account',
        validState: newValue
      })
    }
  }

  @Watch('restoredFromSessionState')
  onRestoredFromSessionState(newValue: boolean) {
    if (newValue) {
      this.updateDataFromStore()
    }
  }

  private get customerSuppliedEmailAddress(): string {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.signUpModule.signupInfo.customerContactInfo.emailAddress!
  }

  @Watch('customerSuppliedEmailAddress')
  onCustomerSuppliedEmailAddressChanged(newValue: string) {
    this.emailAddress = newValue
  }

  private async updatePersonalInfo() {
    if (this.localEmailAddress && validator.isEmail(this.localEmailAddress)) {
      await this.signUpModule.UpdateUsername(this.localEmailAddress)
    } else if (this.emailAddress) {
      await this.signUpModule.UpdateUsername(this.emailAddress)
    }
  }

  private async updateAccountInfo() {
    if (this.localEmailAddress && validator.isEmail(this.localEmailAddress)) {
      await this.signUpModule.UpdateUsername(this.localEmailAddress)
    } else if (this.emailAddress) {
      await this.signUpModule.UpdateUsername(this.emailAddress)
    }
    await this.signUpModule.AddCustomerPassword(this.localPassword)
  }

  created() {
    this.updateDataFromStore()
  }

  get showAccountInfo() {
    return this.signUpModule.IsFaceBookConnected === false && this.signUpModule.hasKnownSubscriberId === false
  }

  private updateDataFromStore() {
    if (
      this.signUpModule.signupInfo.customerContactInfo.emailAddress &&
      validator.isEmail(this.signUpModule.signupInfo.customerContactInfo.emailAddress)
    ) {
      this.emailAddress = this.signUpModule.signupInfo.customerContactInfo.emailAddress
    }
  }

  async assignPassword() {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.signUpModule.AddCustomerPassword(this.localPassword)
    }
  }

  emailRules = [
    (v: string) => !!v || 'E-mail is required',
    (v: string) => /.+@.+\..+/.test(v) || 'E-mail appears to be invalid.'
  ]

  passwordRules = [
    (v: string) => !!v || 'Password is required',
    (v: string) => v.length >= 8 || 'Password must be at least 8 characters.'
  ]
}
