



















import { Component, Vue, Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import SignupModule from '@/store/modules/signup'
import { CommonItemConfiguration } from '@/modules/common'

@Component({ components: { } })
export default class MobilePackImage extends Vue {
  signUpModule: SignupModule = getModule(SignupModule, this.$store);

  @Prop({ type: Object as () => CommonItemConfiguration })
  private CommonItemConfiguration!: CommonItemConfiguration;

  @Prop()
  private Index!: number

  get isSelected() {
    if (this.CommonItemConfiguration &&
      this.CommonItemConfiguration.name ===
      this.signUpModule.selectedCommonItemConfiguration?.name) { return true }
    return false
  }

  get imageHeight() {
    return `${(this.$vuetify.breakpoint.width / 2) * 0.6666666666666667}px`
  }

  get PackName() {
    if (this.CommonItemConfiguration) {
      return this.CommonItemConfiguration.name
    }
    return 'Build Your Own'
  }

  viewCommonItemConfiguration() {
    this.$emit('common-item-configuration-view', { id: this.CommonItemConfiguration.id })
  }

  buildCustomPack() {
    this.$emit('pick-proteins')
  }

  get thumbnail() {
    if (this.CommonItemConfiguration) {
      return `https://assets.steakchop.com/pack-thumbnails/${this.CommonItemConfiguration.id}.jpg`
    }
    return null
  }
}
