






























































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import SignupModule from '@/store/modules/signup'
import { getModule } from 'vuex-module-decorators'
import validator from 'validator'
import { Prop, Watch } from 'vue-property-decorator'
import { VFBLoginScope as VFacebookLoginScope } from 'vue-facebook-login-component'
import { SubscriberStatus } from '../../../modules/common'

@Component({
  components: { VFacebookLoginScope }
})
export default class BasicsEmailOnly extends Vue {
  signUpModule: SignupModule = getModule(SignupModule, this.$store)

  private localEmailAddress: string | undefined = ''

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private scope: any

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private fb: any

  private disableEmail = false

  private emailRules = [
    (v: string) => !!v || 'E-mail is required',
    (v: string) => /.+@.+\..+/.test(v) || 'E-mail appears to be invalid.'
  ]

  private lazy = true

  private valid = false

  @Prop()
  packType!: string | undefined

  @Prop()
  customize!: string | undefined

  get computeSizes() {
    if (this.$vuetify.breakpoint.xs) {
      return '20.4vw'
    }
    if (this.$vuetify.breakpoint.sm) {
      return '12vw'
    }
    if (this.$vuetify.breakpoint.xl) {
      return '6vw'
    }
    return '8vw'
  }

  // eslint-disable-next-line class-methods-use-this
  get fbKey() {
    return process.env.VUE_APP_FACEBOOK_API
  }

  fbDisconnect() {
    this.signUpModule.SetThirdPartyCredentials({ id: '', provider: '' })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSdkInit(sdk: { FB: any; scope: any }) {
    this.fb = sdk.FB
    this.scope = sdk.scope
  }

  fbLogin() {
    this.fb.login(
      (loginResponse: { status: string }) => {
        if (loginResponse.status === 'connected') {
          this.fb.api(
            '/me',
            { fields: 'email, last_name, first_name' },
            async (apiResponse: { email: string; last_name: string; first_name: string; id: string }) => {
              const userStatus = await this.signUpModule.UpdateUsername(apiResponse.email)
              if (userStatus === SubscriberStatus.None) {
                await this.signUpModule.AddCustomerName({
                  firstName: apiResponse.first_name,
                  lastName: apiResponse.last_name
                })

                await this.signUpModule.SetThirdPartyCredentials({
                  id: apiResponse.id,
                  provider: 'fb'
                })
                try {
                  this.fb.logout()
                  // eslint-disable-next-line no-empty
                } catch {}
                this.moveToNextStep()
              }
            }
          )
        }
      },
      {
        scope: 'email',

        return_scopes: true
      }
    )
  }

  get emailDisabled() {
    return (
      this.signUpModule.IsFaceBookConnected === false &&
      this.signUpModule.subscriberStatus === SubscriberStatus.None &&
      this.disableEmail === true
    )
  }

  renableEmail() {
    if (!this.signUpModule.IsFaceBookConnected) {
      this.disableEmail = false
    }
  }

  get emailAddress(): string | undefined {
    return this.localEmailAddress
  }

  set emailAddress(value: string | undefined) {
    this.localEmailAddress = value
  }

  private get restoredFromSessionState(): boolean {
    return this.signUpModule.restoredFromSessionState
  }

  private get isFaceBookConnected(): boolean {
    return this.signUpModule.IsFaceBookConnected
  }

  async createAccount() {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      if (this.disableEmail === false) {
        const result = await this.updatePersonalInfo()
        if (result === SubscriberStatus.None) {
          this.disableEmail = true
          this.moveToNextStep()
        }
      }
    }
  }

  private moveToNextStep() {
    if (this.signUpModule.IsCommonItemConfigurationSelected && this.signUpModule.hasCustomizedCommonConfiguration) {
      this.$router.push('/shopping/pick-pack/true')
    } else if (
      this.signUpModule.IsCommonItemConfigurationSelected === false &&
      this.signUpModule.selectedItems.length > 1
    ) {
      this.$router.push('/shopping/pick-pack/true')
    } else if (this.customize || this.packType) {
      if (this.packType === 'std' && this.customize === 'true') {
        this.$router.push('/shopping/pick-pack/true')
      }
      if (this.packType === 'std' && this.customize === 'false') {
        this.$router.push('/shopping/details')
      }
      if (this.packType === 'byo') {
        this.$router.push('/shopping/pick-pack/false/true')
      }
    } else {
      this.$router.push('/shopping/pick-pack')
    }
  }

  @Watch('restoredFromSessionState')
  onRestoredFromSessionState(newValue: boolean) {
    if (newValue) {
      this.updateDataFromStore()
    }
  }

  created() {
    this.updateDataFromStore()
  }

  private updateDataFromStore() {
    if (
      this.signUpModule.signupInfo.customerContactInfo.emailAddress &&
      validator.isEmail(this.signUpModule.signupInfo.customerContactInfo.emailAddress)
    ) {
      this.emailAddress = this.signUpModule.signupInfo.customerContactInfo.emailAddress
    }
  }

  private async updatePersonalInfo(): Promise<SubscriberStatus> {
    if (this.valid) {
      if (this.localEmailAddress && validator.isEmail(this.localEmailAddress)) {
        return this.signUpModule.UpdateUsername(this.localEmailAddress)
      }
      if (this.emailAddress) {
        return this.signUpModule.UpdateUsername(this.emailAddress)
      }
    }
    return SubscriberStatus.Unknown
  }

  private get customerSuppliedEmailAddress(): string {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.signUpModule.signupInfo.customerContactInfo.emailAddress!
  }

  @Watch('customerSuppliedEmailAddress')
  onCustomerSuppliedEmailAddressChanged(newValue: string) {
    this.emailAddress = newValue
  }
}
