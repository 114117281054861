











































































import { Component, Vue } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import SignupModule from '@/store/modules/signup'
import { ProteinType } from '@/modules/common'
import ProteinButton from '@/components/signup/CustomProteinSelector/ProteinButton/index.vue'
import CustomNavigation from '@/components/signup/CustomNavigation/CustomNavigation.vue'

@Component({ components: { ProteinButton, CustomNavigation } })
export default class CustomProteinSelector extends Vue {
  signUpModule: SignupModule = getModule(SignupModule, this.$store);

  selectedProteins: Array<ProteinType> = [];

  proteinCSS(proteinType: ProteinType): string {
    const index = this.selectedProteins.indexOf(proteinType)
    if (index > -1) {
      return 'selected'
    }
    return 'unselected'
  }

  toggle(proteinType: ProteinType) {
    const index = this.selectedProteins.indexOf(proteinType)
    if (index === -1) {
      this.selectedProteins.push(proteinType)
    } else {
      this.selectedProteins.splice(index, 1)
    }
  }

  get IsCommonPackConfiguration() {
    return this.signUpModule.IsCommonItemConfigurationSelected
  }

  mounted() {
    if (
      this.signUpModule.selectedProteins &&
      this.signUpModule.IsCommonItemConfigurationSelected === false
    ) {
      this.selectedProteins.push(...this.signUpModule.selectedProteins)
    }
  }

  customize() {
    this.$emit('proteins-selected', this.selectedProteins)
  }
}
