



import { Component, Vue, Prop } from 'vue-property-decorator'
import SignupModule from '@/store/modules/signup'
import { getModule } from 'vuex-module-decorators'
import Cookie from 'js-cookie'
import { SubscriberStatus } from '@/modules/common'

@Component({ components: {} })
export default class Resume extends Vue {
  signUpModule: SignupModule = getModule(SignupModule, this.$store);

  @Prop()
  cartId: string | undefined;

  @Prop()
  promotionCode: string | undefined;

  @Prop()
  goingBack: string | undefined;

  @Prop()
  packType: string | undefined;

  @Prop()
  customize!: string | undefined;

  private determinePackRoute(defaultPath: string | undefined = undefined) {
    if (this.packType === 'byo') {
      return '/shopping/pick-pack/false/true'
    }
    if (this.packType === 'std' && this.customize === 'false') {
      return '/shopping/details'
    }
    if (this.packType === 'std' && this.customize === 'true') {
      return '/shopping/pick-pack/true'
    }
    if (defaultPath) {
      return defaultPath
    }
    return '/shopping/pick-pack'
  }

  async mounted() {
    if (this.goingBack === 'true') {
      this.$router.back()
    } else {
      let cartId = ''
      let foundCart = false

      if (this.cartId) {
        cartId = this.cartId
      } else if (Cookie.get('sc-cart')) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        cartId = Cookie.get('sc-cart')!
      }

      let promotionCode = ''
      if (this.promotionCode) {
        promotionCode = this.promotionCode
      } else if (Cookie.get('scPC')) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        promotionCode = Cookie.get('scPC')!
      }

      const signupState = sessionStorage.getItem('signup-steakchop')
      if (signupState) {
        await this.signUpModule.InitializeFromSessionState(signupState)
      } else if (this.signUpModule.signupId !== cartId && cartId !== '') {
        foundCart = await this.signUpModule.InitializeFromActiveCart({
          cartId,
          promotionCode
        })
      } else {
        foundCart = cartId !== ''
      }
      // let path = '/shopping/contact';
      let path = '/shopping/pick-pack'
      if (foundCart === false && this.signUpModule.signupId === '') {
        await this.signUpModule.Initialize()
        if (promotionCode !== '') {
          await this.signUpModule.AddPromotion(promotionCode)
        }
        if (this.signUpModule.hasKnownSubscriberId) {
          path = '/shopping/pick-pack'
        } else {
          path = this.determinePackRoute()
        }
        // } else if (this.packType) {
        //   path = `/shopping/contact/${this.packType}/${this.customize}`;
        // } else {
        //   path = '/shopping/contact';
        // }
      } else if (
        this.signUpModule.signupId !== '' &&
        this.signUpModule.signupInfo.customerContactInfo.emailAddress !== '' &&
        (this.signUpModule.signupInfo.shippingAddress.address.postalCode
          .length === 5 ||
          this.signUpModule.subscriberStatus === SubscriberStatus.None) &&
        this.signUpModule.selectedItems.length >= 4
      ) {
        if (this.packType === 'byo') {
          path = '/shopping/pick-pack/false/true'
        } else if (this.packType === 'std') {
          path = '/shopping/details'
        } else {
          path = '/shopping/checkout'
        }
      } else if (
        this.signUpModule.IsCommonItemConfigurationSelected &&
        this.signUpModule.hasCustomizedCommonConfiguration
      ) {
        path = this.determinePackRoute('/shopping/pick-pack/true')
      } else if (
        this.signUpModule.IsCommonItemConfigurationSelected === false &&
        this.signUpModule.selectedItems.length > 1
      ) {
        path = this.determinePackRoute('/shopping/pick-pack/true')
      } else {
        path = this.determinePackRoute()

        // if (this.packType) {
        //   path = `/shopping/contact/${this.packType}/${this.customize}`;
        // } else {
        //   path = '/shopping/contact';
        // }
      }
      this.$router.push(path)
    }
  }
}
