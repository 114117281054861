

























import Vue from 'vue'
import Component from 'vue-class-component'
import SignupModule from '@/store/modules/signup'
import { getModule } from 'vuex-module-decorators'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class AlreadyAnAccount extends Vue {
  signUpModule: SignupModule = getModule(SignupModule, this.$store);

  @Prop()
  private hasActiveAccount!: boolean;

  @Prop()
  private emailAddress!: string;

  get canShow() {
    return this.emailAddress && this.emailAddress.length > 0
  }

  private changeEmail() {
    this.$emit('change-email')
  }
}
