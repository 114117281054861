






























import Vue from 'vue'
import Component from 'vue-class-component'
import SignupModule from '@/store/modules/signup'
import { getModule } from 'vuex-module-decorators'

@Component({
  components: {}
})
export default class GettingStartedIssue extends Vue {
  signUpModule: SignupModule = getModule(SignupModule, this.$store);

  private get ShowMe() {
    return this.signUpModule.HasGettingStartedErrors
  }

  private get ErrorMessage() {
    return this.signUpModule.errorMessage
  }

  private get IsAccountError() {
    return this.signUpModule.HasInvalidZipCode === false && this.signUpModule.CannotShip === false
  }

  private get ButtonText() {
    if (this.signUpModule.HasInvalidZipCode) { return 'Correct Zip Code' }
    if (this.signUpModule.CannotShip) { return 'Enter New Zip Code' }
    if (this.signUpModule.HasExistingAccountWithBillingIssue) { return 'Goto Your Account' }
    if (this.signUpModule.HasExistingAccountWithNoIssues) { return 'Goto Your Account' }
    if (this.signUpModule.HasExistingAccountThatCancelled) { return 'Goto Your Account' }
    return 'Ok'
  }

  private DoAction() {
    this.$emit('clear-error')
  }
}
