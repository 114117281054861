



























import Vue from 'vue'
import Component from 'vue-class-component'

import { Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class DifferentCenter extends Vue {
  @Prop()
  private cityState!: string;

  @Prop()
  private differentCenter!: boolean;

  private selectItems() {
    this.$emit('select-new-items')
  }

  private revert() {
    this.$emit('revert-postalcode-change')
  }
}
