

































import Vue from 'vue'
import Component from 'vue-class-component'
import SignupModule from '@/store/modules/signup'
import { getModule } from 'vuex-module-decorators'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class FrequencySelector extends Vue {
  signUpModule: SignupModule = getModule(SignupModule, this.$store);

  @Prop()
  homePageMode!: boolean;

  frequency: Array<number> = [4, 6, 8];

  edit = false;

  get Frequency() {
    return this.signUpModule.signupInfo.subscriptionConfiguration.frequency
  }

  set Frequency(value: number) {
    if (value) {
      this.$emit('frequency-changed', value)
      if (!this.homePageMode) {
        this.edit = false
      }
    }
  }

  mounted() {
    if (this.homePageMode) {
      this.edit = true
    }
  }
}
