









import Vue from 'vue'
import Component from 'vue-class-component'
import SignupModule from '@/store/modules/signup'
import { getModule } from 'vuex-module-decorators'

@Component({
  components: {}
})
export default class PromotionalCodeDisplay extends Vue {
  private promotionalCode = '';

  get hasPromotionalCodeApplied() {
    return this.signUpModule.signupInfo.promotionCode.isValid
  }

  signUpModule: SignupModule = getModule(SignupModule, this.$store);

  get promotionalCodeMessage() {
    return this.signUpModule.signupInfo.promotionCode.messaging
  }

  async removeCode() {
    await this.signUpModule.RemovePromotion()
  }
}
