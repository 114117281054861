

























import Vue from 'vue'
import Component from 'vue-class-component'
import SignupModule from '@/store/modules/signup'
import { getModule } from 'vuex-module-decorators'

@Component({
  components: {}
})
export default class SignupIssue extends Vue {
  signUpModule: SignupModule = getModule(SignupModule, this.$store);

  private get ShowMe() {
    return this.signUpModule.HasSignupErrors
  }

  private get ErrorMessage() {
    return this.signUpModule.errorMessage
  }

  private get IsAccountError() {
    return this.signUpModule.HasInvalidZipCode === false && this.signUpModule.CannotShip === false
  }
}
