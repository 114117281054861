



import { Component, Vue, Prop } from 'vue-property-decorator'
import SignupModule from '@/store/modules/signup'
import { getModule } from 'vuex-module-decorators'
import CommonModule from '@/store/modules/common'
import GettingStartedIssue from '@/components/signup/AttentionRequired/GettingStatedIssue/index.vue'
import Cookie from 'js-cookie'
import { PackSize, Frequency, ProteinType } from '../../../modules/common'

@Component({ components: { GettingStartedIssue } })
export default class DoWeShipComponent extends Vue {
  valid = false;

  lazy = true;

  postalCode = '';

  mask = '#####';

  actualDisplay = false;

  confirmShipping = false;

  signUpModule: SignupModule = getModule(SignupModule, this.$store);

  commonModule: CommonModule = getModule(CommonModule, this.$store);

  @Prop()
  autoStart!: boolean;

  @Prop()
  emailAddress: string | undefined;

  @Prop()
  zipCode: string | undefined;

  @Prop()
  promotionCode: string | undefined;

  @Prop()
  proteinList: string | undefined;

  @Prop()
  size: PackSize | undefined;

  @Prop()
  frequency: Frequency | undefined;

  @Prop()
  packType: string | undefined;

  @Prop()
  customize: string | undefined;

  @Prop()
  goingBack: string | undefined;

  async mounted() {
    if (this.goingBack === 'true') {
      this.$router.back()
    } else {
      if (this.proteinList || this.size || this.frequency) {
        const info: {
          proteinList: Array<ProteinType>;
          packSize: PackSize;
          frequency: Frequency;
        } = {
          proteinList: [],
          packSize: PackSize.Unknown,
          frequency: Frequency.Unknown
        }

        if (this.promotionCode && this.promotionCode !== 'na') {
          this.$emit('promotion-code-direct-link', this.promotionCode)
        } else if (Cookie.get('scPC')) {
          this.$emit('promotion-code-direct-link', Cookie.get('scPC'))
        }

        if (this.size) {
          info.packSize = +this.size
        }
        if (this.frequency) {
          info.frequency = +this.frequency
        }
        if (this.proteinList && this.proteinList !== '0') {
          // eslint-disable-next-line no-restricted-syntax
          for (const protein of this.proteinList) {
            info.proteinList.push(+protein)
          }
        }
        this.$emit('direct-checkout-link', info)
      }

      let promotionCode = ''

      if (this.promotionCode && this.promotionCode !== 'na') {
        promotionCode = this.promotionCode
      } else if (Cookie.get('scPC')) {
        this.promotionCode = Cookie.get('scPC')!
      }
      if (promotionCode) {
        if (this.packType && this.customize) {
          this.$router.push({ path: `/shopping/resume/${promotionCode}`, params: { customize: this.customize, packType: this.packType } })
        } else {
          this.$router.push(`/shopping/resume/${promotionCode}`)
        }
      } else if (this.packType && this.customize) {
        this.$router.push({ path: '/shopping/resume', params: { customize: this.customize, packType: this.packType } })
      } else {
        this.$router.push('/shopping/resume')
      }
    }
  }
}
