









































































































































































































































import Vue from 'vue'
import m from 'moment'
import Component from 'vue-class-component'
import SignupModule from '@/store/modules/signup'
import { getModule } from 'vuex-module-decorators'
import PromotionalCodeEntry from '@/components/signup/Checkout/PromotionalCode/Entry/index.vue'
import PromotionalCodeDisplay from '@/components/signup/Checkout/PromotionalCode/Display/index.vue'
import { ProteinType, PackSize, Frequency } from '../../../../modules/common'

@Component({
  components: { PromotionalCodeEntry, PromotionalCodeDisplay }
})
export default class CartSummmary extends Vue {
  signUpModule: SignupModule = getModule(SignupModule, this.$store);

  loaded = false;

  mounted() {
    this.loaded = true
  }

  editPack() {
    if (
      this.signUpModule.IsCommonItemConfigurationSelected &&
      this.signUpModule.hasCustomizedCommonConfiguration
    ) {
      this.$router.push('/shopping/pick-pack/true/false/true')
    } else if (this.signUpModule.IsCommonItemConfigurationSelected === false) {
      this.$router.push('/shopping/pick-pack/true/false/true')
    } else {
      this.$router.push('/shopping/pick-pack')
    }
  }

  get hasYetToBePlaced() {
    return (
      this.signUpModule.orderId === '' ||
      this.signUpModule.orderId === undefined
    )
  }

  get hasPlaced() {
    return (
      this.signUpModule.orderId !== '' &&
      this.signUpModule.orderId !== undefined
    )
  }

  // eslint-disable-next-line class-methods-use-this
  get isProduction() {
    return process.env.NODE_ENV === 'production' && this.loaded
  }

  get deliveryWeeks() {
    return this.signUpModule.EligibleShippingWeeks.map((p) => m(p).format('L'))
  }

  get shippingWeek() {
    if (this.signUpModule.signupInfo.firstShipmentDate) {
      return this.signUpModule.signupInfo.firstShipmentDate
    }
    return this.signUpModule.EligibleShippingWeeks[0]
  }

  get firstDeliveryDay(): string {
    return m(this.shippingWeek).add(2, 'days').format('ddd L')
  }

  get lastDeliveryDay(): string {
    return m(this.shippingWeek).add(4, 'days').format('ddd L')
  }

  get formattedShippingWeek() {
    if (this.signUpModule.signupInfo.firstShipmentDate) {
      return m(this.signUpModule.signupInfo.firstShipmentDate).format('L')
    }
    return m(this.signUpModule.EligibleShippingWeeks[0]).format('L')
  }

  set formattedShippingWeek(value: string) {
    this.$emit('set-shipping-week', m(value))
  }

  get packName() {
    return this.signUpModule.PackName
  }

  get packItems(): Array<{
    quantity: number;
    name: string;
    isFreeItem: boolean;
  }> {
    return this.signUpModule.GroupedSelectItems
  }

  get Size() {
    return (
      this.signUpModule.signupInfo.subscriptionConfiguration.packSize ===
      PackSize.Large
    )
  }

  set Size(value: boolean) {
    this.$emit('pack-size-changed', value ? PackSize.Large : PackSize.Standard)
  }

  get Frequency() {
    return this.signUpModule.signupInfo.subscriptionConfiguration.frequency
  }

  set Frequency(value: Frequency) {
    this.$emit('frequency-changed', value)
  }

  get packSize(): string {
    return PackSize[
      this.signUpModule.signupInfo.subscriptionConfiguration.packSize
    ]
  }

  get discount(): number {
    return this.signUpModule.discount
  }

  get frequency(): string {
    return `Every ${this.signUpModule.signupInfo.subscriptionConfiguration.frequency} weeks`
  }

  // eslint-disable-next-line class-methods-use-this
  ProteinName(proteinType: ProteinType) {
    return ProteinType[proteinType]
  }
}
