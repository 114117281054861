























































import Vue from 'vue'
import Component from 'vue-class-component'
import SignupModule from '@/store/modules/signup'
import { getModule } from 'vuex-module-decorators'
import {
  ProteinType,
  PackSize,
  Frequency,
  ShippingEligiblity,
  ShippingCenter,
  SubscriberStatus
} from '@/modules/common'
import StripeModule from '@/store/modules/stripe'
import AlreadyAnAccount from '@/components/signup/AttentionRequired/AlreadyAnAccount/index.vue'
import DifferentCenter from '@/components/signup/AttentionRequired/DifferentCenter/index.vue'
import NoShip from '@/components/signup/AttentionRequired/NoShip/index.vue'

import { Route } from 'vue-router'
import { Watch } from 'vue-property-decorator'
import CommonModule from '@/store/modules/common'
import router from '../router'

@Component({
  components: {
    AlreadyAnAccount,
    DifferentCenter,
    NoShip
  }
})
export default class Signup extends Vue {
  signUpModule: SignupModule = getModule(SignupModule, this.$store)

  commonModule: CommonModule = getModule(CommonModule, this.$store)

  stripeModule: StripeModule = getModule(StripeModule, this.$store)

  overlay = false

  noShip = false

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  gtm = (Vue as any).gtm

  differentCenterInfo: { cityState: string; postalCode: string } = { cityState: '', postalCode: '' }

  directCheckoutInfo: { proteinList: Array<ProteinType>; packSize: PackSize; frequency: Frequency } = {
    proteinList: [],
    packSize: PackSize.Unknown,
    frequency: Frequency.Unknown
  }

  @Watch('$route')
  // eslint-disable-next-line class-methods-use-this
  onRouteChange(to: Route, from: Route) {
    document.title = to.meta?.title || 'SteakChop'

    if (to.path === '/shopping/getting-started' && from.path.startsWith('/shopping')) {
      router.back()
    }
    // if (this.signUpModule.orderId !== '' && to.path !== '/shopping/receipt') {
    //   this.$router.push('/shopping/receipt');
    // } else {
    if (from.path === '/shopping/pick-size') {
      this.gtm.trackEvent({
        event: 'checkoutOption',
        ecommerce: {
          checkout_option: {
            actionField: {
              step: 4,
              option:
                this.signUpModule.signupInfo.subscriptionConfiguration.packSize === PackSize.Standard
                  ? 'standard'
                  : 'large'
            }
          }
        }
      })
    }

    if (from.path === '/shopping/pick-frequency') {
      this.gtm.trackEvent({
        event: 'checkoutOption',
        ecommerce: {
          checkout_option: {
            actionField: {
              step: 5,
              option: `${this.signUpModule.signupInfo.subscriptionConfiguration.frequency} weeks`
            }
          }
        }
      })
    }
    // }
  }

  get ready() {
    return this.signUpModule.itemsAttachedToConifgs
  }

  get emailAddress() {
    return this.signUpModule.signupInfo.customerContactInfo.emailAddress
  }

  get hasActiveAccount() {
    return (
      this.signUpModule.subscriberStatus === SubscriberStatus.Active ||
      this.signUpModule.subscriberStatus === SubscriberStatus.Suspended ||
      this.signUpModule.subscriberStatus === SubscriberStatus.Canceled
    )
  }

  get differentCenter() {
    return this.differentCenterInfo.postalCode.length === 5 && this.noShip === false
  }

  get readyForSign() {
    return this.signUpModule.ReadyToSignUp
  }

  private async substituteItem(input: { itemIndex: number; newItemId: string }) {
    await this.signUpModule.SubstituteItem(input)
  }

  // eslint-disable-next-line class-methods-use-this
  private pickProteins(size: boolean) {
    if (size === true) {
      this.signUpModule.SetPackSize(PackSize.Large)
    } else {
      this.signUpModule.SetPackSize(PackSize.Standard)
    }
    router.push('/shopping/pick-pack/false/true')
  }

  private setShippingWeek(value: Date) {
    this.signUpModule.SetShippingWeek(value)
  }

  private async changeEmail() {
    await this.signUpModule.UpdateUsername('')
  }

  private async promotionCodeDirectLink(promotionCode: string) {
    await this.signUpModule.AddPromotion(promotionCode)
  }

  private get packPreSelected() {
    return this.directCheckoutInfo.proteinList.length > 0
  }

  private get sizePreSelected() {
    return this.directCheckoutInfo.packSize !== PackSize.Unknown
  }

  private get frequencyPreSelected() {
    return this.directCheckoutInfo.frequency !== Frequency.Unknown
  }

  private directCheckoutLink(input: { proteinList: Array<ProteinType>; packSize: PackSize; frequency: Frequency }) {
    this.directCheckoutInfo = input
  }

  private async startSignupProcess() {
    const token = await this.stripeModule.GetToken(this.signUpModule.StripeTokenValues)
    this.overlay = token !== null
    if (this.overlay) {
      const result = await this.signUpModule.SignupSubscriber(token)
      this.overlay = false
      if (result.orderId) {
        this.gtm.trackEvent({
          event: 'purchase',
          ecommerce: {
            purchase: {
              actionField: {
                id: result.orderId,
                affiliation: 'SteakChop - Online Store',
                revenue: this.signUpModule.finalPrice,
                currencyCode: 'USD',
                tax: 0.0,
                shipping: 0.0,
                coupon: this.signUpModule.signupInfo.promotionCode.isValid
                  ? this.signUpModule.signupInfo.promotionCode.code
                  : ''
              },
              products: [
                {
                  name: this.signUpModule.PackName,
                  id: this.signUpModule.PackId,
                  price: this.signUpModule.subscriptionRetailPrice,
                  brand: 'SteakChop',
                  category: 'Custom Pack',
                  variant:
                  this.signUpModule.selectedCommonItemConfiguration.shippingCenter === ShippingCenter.WestCoastCenter1
                    ? 'Crystal Creek'
                    : this.signUpModule.selectedCommonItemConfiguration.shippingCenter === ShippingCenter.EastCoastCenter2 ? 'Heeps'
                      : 'Schiffs',
                  quantity: 1
                }
              ]
            }
          }
        })
        router.push('/shopping/receipt')
      }
    }
  }

  private notifyDifferentCenter(input: { cityState: string; postalCode: string }) {
    this.differentCenterInfo.cityState = input.cityState
    this.differentCenterInfo.postalCode = input.postalCode
  }

  private notifyIneligible(input: { cityState: string; postalCode: string }) {
    this.differentCenterInfo.cityState = input.cityState
    this.differentCenterInfo.postalCode = input.postalCode
    this.noShip = true
  }

  private revertNoShip() {
    this.noShip = false
    this.differentCenterInfo.cityState = ''
    this.differentCenterInfo.postalCode = ''
  }

  private async processDifferentCenter(): Promise<void> {
    await this.signUpModule.UpdateShippingPostalCode({ postalCode: this.differentCenterInfo.postalCode })
    this.differentCenterInfo.cityState = ''
    this.differentCenterInfo.postalCode = ''
  }

  /* new stuff */

  async verifyShipping() {
    await this.signUpModule.Initialize()
    if (
      this.signUpModule.shippingEligiblity === ShippingEligiblity.Eligible &&
      this.signUpModule.subscriberStatus === SubscriberStatus.None
    ) {
      // let route = '/shopping/contact';
      let route = '/shopping/details'
      if (this.packPreSelected) {
        this.signUpModule.CustomizeCustomItemConfiguration(this.directCheckoutInfo.proteinList)
      }
      if (this.sizePreSelected) {
        this.signUpModule.SetPackSize(this.directCheckoutInfo.packSize)
      }
      if (this.frequencyPreSelected) {
        this.signUpModule.SetFrequency(this.directCheckoutInfo.frequency)
      }

      if (this.packPreSelected && this.sizePreSelected && this.frequencyPreSelected) {
        if (this.signUpModule.signupInfo.customerContactInfo.emailAddress !== '') {
          route = '/shopping/checkout'
        } else {
          route = '/shopping/details'
        }
      }
      router.push(route)
    } else {
      this.signUpModule.ProcessSignupError()
    }
  }

  private clearError() {
    this.signUpModule.ClearErrorMessage()
  }

  async created() {
    const signupState = sessionStorage.getItem('signup-steakchop')
    if (
      signupState &&
      router.currentRoute.path !== '/shopping/getting-started' &&
      router.currentRoute.path !== '/shopping/resume'
    ) {
      await this.signUpModule.InitializeFromSessionState(signupState)
    }
    let route = ''
    switch (router.currentRoute.path) {
      case '/shopping/pick-frequency':
      case '/shopping/checkout':
        if (this.signUpModule.shippingEligiblity !== ShippingEligiblity.Eligible) {
          route = '/shopping/pick-pack'
        } else if (this.signUpModule.selectedItems.length === 0) {
          route = '/shopping/pick-pack'
        } else if (this.signUpModule.signupInfo.subscriptionConfiguration.packSize === PackSize.Unknown) {
          route = '/shopping/details'
        } else if (this.signUpModule.signupInfo.subscriptionConfiguration.frequency === Frequency.Unknown) {
          route = '/shopping/details'
        }
        break
      case '/shopping/pick-pack':
        if (this.signUpModule.shippingEligiblity !== ShippingEligiblity.Eligible) {
          route = ''
          await this.signUpModule.Initialize()
        }
        break
      case '/shopping/pick-size':
      case '/shopping/pick-items':
        if (this.signUpModule.shippingEligiblity !== ShippingEligiblity.Eligible) {
          route = '/shopping/pick-pack'
        } else if (this.signUpModule.selectedItems.length === 0) {
          route = '/shopping/pick-pack'
        }
        break
      default:
        break
    }
    if (route !== '') {
      router.push(route)
    }
  }

  private updatePackSize(packSize: PackSize) {
    if (packSize === PackSize.Large) {
      this.signUpModule.DoubleUp()
    } else {
      this.signUpModule.SingleDown()
    }
  }

  private updateFrequency(frequency: Frequency) {
    this.signUpModule.SetFrequency(frequency)
  }

  private async proteinsSelected(proteins: Array<ProteinType>) {
    await this.signUpModule.CustomizeCustomItemConfiguration(proteins)
    router.push('/shopping/pick-pack/true')
  }

  private async viewCommonItemConfiguration(input: { id: string }) {
    if (this.signUpModule.selectedCommonItemConfiguration.id !== '' || this.signUpModule.selectedProteins.length > 0) {
      this.reportItemRemove()
    }
    await this.signUpModule.PurchaseCommonItemConfiguration(input.id)
  }

  private async commonItemConfigurationSelected(input: {
    id: string
    personalize: boolean
    frequency: Frequency
    size: PackSize
  }) {
    if (this.signUpModule.selectedCommonItemConfiguration.id !== '' || this.signUpModule.selectedProteins.length > 0) {
      this.reportItemRemove()
    }
    await this.signUpModule.PurchaseCommonItemConfiguration(input.id)
    this.signUpModule.SetFrequency(input.frequency)
    this.signUpModule.SetPackSize(PackSize.Standard)
    if (input.size === PackSize.Large) {
      this.signUpModule.DoubleUp()
    }
    this.reportItemAdd()
    if (input.personalize) {
      router.push('/shopping/pick-pack/true')
    } else if (this.signUpModule.signupInfo.customerContactInfo.emailAddress !== '') {
      router.push('/shopping/checkout')
    } else {
      // router.push('/shopping/contact');
      router.push('/shopping/details')
    }
  }

  private reportItemAdd() {
    this.gtm.trackEvent({
      event: 'addToCart',
      ecommerce: {
        currencyCode: 'USD',
        add: {
          products: [
            {
              name: this.signUpModule.PackName,
              id: this.signUpModule.PackId,
              price: this.signUpModule.subscriptionRetailPrice,
              brand: 'SteakChop',
              catergory: this.signUpModule.selectedCommonItemConfiguration.id !== '' ? 'Popular Packs' : 'Custom Pack',
              variant:
                this.signUpModule.selectedCommonItemConfiguration.shippingCenter === ShippingCenter.WestCoastCenter1
                  ? 'Crystal Creek'
                  : this.signUpModule.selectedCommonItemConfiguration.shippingCenter === ShippingCenter.EastCoastCenter2 ? 'Heeps'
                    : 'Schiffs',
              quantity: 1
            }
          ]
        }
      }
    })
  }

  private reportItemRemove() {
    this.gtm.trackEvent({
      event: 'removeFromCart',
      ecommerce: {
        remove: {
          products: [
            {
              name: this.signUpModule.PackName,
              id: this.signUpModule.PackId,
              price: this.signUpModule.subscriptionRetailPrice,
              brand: 'SteakChop',
              catergory: this.signUpModule.selectedCommonItemConfiguration.id !== '' ? 'Popular Packs' : 'Custom Pack',
              variant:
              this.signUpModule.selectedCommonItemConfiguration.shippingCenter === ShippingCenter.WestCoastCenter1
                ? 'Crystal Creek'
                : this.signUpModule.selectedCommonItemConfiguration.shippingCenter === ShippingCenter.EastCoastCenter2 ? 'Heeps'
                  : 'Schiffs',
              quantity: 1
            }
          ]
        }
      }
    })
  }

  // eslint-disable-next-line class-methods-use-this
  private async doneSelectingItems() {
    if (this.signUpModule.signupInfo.customerContactInfo.emailAddress !== '') {
      router.push('/shopping/details')
    } else {
      router.push('/shopping/details')
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private async cancelCustomization() {
    router.push('/shopping/pick-pack')
  }
}
